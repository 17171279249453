import type { Resource } from 'i18next'

const buildResource = async (context: __WebpackModuleApi.RequireContext) => {
  const resourceEntries = await Promise.all(
    context.keys().map(async (fileName) => {
      const namespace = fileName.replace('./', '').replace('.json', '')
      const contents = (await context(fileName)) as Resource
      return [namespace, contents]
    }) as Promise<[string, Resource]>[],
  )

  return resourceEntries.reduce<Resource>((acc, [namespace, contents]) => {
    return {
      ...acc,
      [namespace]: contents,
    }
  }, {})
}

const requireDev = require.context('../locales/dev', false, /\.json$/, 'lazy-once')
const requireSv = require.context('../locales/sv', false, /\.json$/, 'lazy-once')
const requireEn = require.context('../locales/en', false, /\.json$/, 'lazy-once')
const requireFi = require.context('../locales/fi', false, /\.json$/, 'lazy-once')
const requireNb = require.context('../locales/nb', false, /\.json$/, 'lazy-once')
const requireFr = require.context('../locales/fr', false, /\.json$/, 'lazy-once')

export const langs = {
  dev: () => buildResource(requireDev),
  sv: () => buildResource(requireSv),
  en: () => buildResource(requireEn),
  fi: () => buildResource(requireFi),
  nb: () => buildResource(requireNb),
  fr: () => buildResource(requireFr),
}
