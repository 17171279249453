'use client'

import '../vendor/intercom-client'

import {
  ApolloProvider,
  RegionProvider,
  ToastProvider,
  UserConsentProvider,
  useTrackClickEvent,
} from '@qasa/app'
import { HelmetProvider } from 'react-helmet-async'
import type { PropsWithChildren } from 'react'
import { QdsProvider } from '@qasa/qds-ui'
import { NuqsAdapter } from 'nuqs/adapters/next/app'

import { LanguageLoader } from '../translations/language-loader'
import { useCurrentLocale } from '../translations/use-current-locale'
import { CookieConsent } from '../ui-page-modules/cookie-consent'
import { Toasts } from '../ui-page-modules/toasts'
import { currentBrand } from '../config'
import { blocketQdsOverrides } from '../ui-styles/blocket'
import { AuthProvider } from '../context/auth-context'
import { BRAND_CONFIG } from '../config/brand-configurations'
import { useEmotionCache } from '../hooks/use-emotion-cache'

export function Providers({ children }: PropsWithChildren) {
  useTrackClickEvent()
  const isBlocket = currentBrand === 'blocket'
  const qdsOverrides = isBlocket ? blocketQdsOverrides : undefined
  const locale = useCurrentLocale()
  const emotionCache = useEmotionCache()

  return (
    <NuqsAdapter>
      <HelmetProvider>
        <ApolloProvider>
          <LanguageLoader>
            <QdsProvider locale={locale} themeOverrides={qdsOverrides} emotionCache={emotionCache}>
              <RegionProvider region={BRAND_CONFIG.countryCode}>
                <UserConsentProvider>
                  <ToastProvider>
                    <AuthProvider>
                      {children}
                      <CookieConsent />
                      <Toasts />
                    </AuthProvider>
                  </ToastProvider>
                </UserConsentProvider>
              </RegionProvider>
            </QdsProvider>
          </LanguageLoader>
        </ApolloProvider>
      </HelmetProvider>
    </NuqsAdapter>
  )
}
